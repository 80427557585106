/* eslint-disable @next/next/no-sync-scripts */

import { Modal, ModalOverlay, ModalContent, ModalHeader, Spacer, ModalCloseButton, ModalBody } from "@chakra-ui/react"

const InteractiveDemoModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal isOpen={true} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent background="transparent" boxShadow="none">
        <ModalHeader>
          <Spacer height="32px" />
        </ModalHeader>
        <ModalCloseButton color="white" size="24px" marginRight="12px" marginTop="24px" />
        <ModalBody paddingBottom="24px" paddingTop="0px">
          <script src="https://js.storylane.io/js/v1/storylane.js"></script>
          <div className="sl-embed">
            <iframe className="sl-demo" src="https://app.storylane.io/demo/mm8duwxeefuk" name="sl-embed" allow="fullscreen; camera; microphone"></iframe>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default InteractiveDemoModal
