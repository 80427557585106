import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, HStack } from "@chakra-ui/react"
import { FiPlay } from "react-icons/fi"

import { CloudImages } from "lib/cosmic/images"

export const VideoModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal isOpen onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent background="transparent">
        <ModalHeader>
          <Text fontFamily="var(--landing-page-font)" fontSize="var(--website-font-size-h2)" color="white">
            What is Integral?
          </Text>
          <HStack marginTop="0px" marginBottom="4px" fontSize="12px" spacing="4px" fontFamily="var(--landing-page-font)">
            <FiPlay color="white" />
            <Text color="#e3e6f7">1:38</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton color="white" size="24px" marginRight="12px" marginTop="24px" />
        <ModalBody paddingBottom="24px" paddingTop="0px">
          <video
            width="100%"
            height="100%"
            autoPlay
            controls
            src={CloudImages.INTEGRAL_LAUNCH_VIDEO}
            style={{
              borderRadius: "8px",
            }}
          ></video>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
