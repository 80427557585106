import { Box, Text, Avatar, Divider, Stack, Image, Flex } from "@chakra-ui/react"

import { CloudImages } from "lib/cosmic/images"

const TestimonialCard = ({ text, author }: { text: string; author: string }) => {
  return (
    <Box maxW="sm" border="1px solid var(--website-color-stroke-link)" p={5} borderRadius="md" boxShadow="1px 1px 20px 0px rgba(99, 37, 227, 0.09);" minWidth="300px">
      <Flex direction="column" justifyContent="space-between" height="100%">
        <Stack spacing={4} align="center">
          <Avatar width="60px" height="60px" bg="var(--website-color-avatar)" color="white" />
          <Divider borderColor="#9566C4" />
          <Text fontSize="20px" fontWeight="bold" textAlign="center" lineHeight="1.2" dangerouslySetInnerHTML={{ __html: text }} />
        </Stack>
        <Stack direction="column" spacing="8px" marginTop="40px">
          <Text textAlign="center" fontSize="20px" maxWidth="220px" mx="auto">
            - {author}
          </Text>
          <Image src={CloudImages.TESTIMONIAL_STARS} alt="Five stars" height="20px" />
        </Stack>
      </Flex>
    </Box>
  )
}

const CARD_CONTENT = [
  {
    quote: "“We needed to move <b style='color:var(--website-color-highlight-alt)'>fast</b> for a client - we couldn’t have done it without Integral”",
    author: "Executive, Top 10 Life Sciences Company",
  },
  {
    quote:
      "“It was <b style='color:var(--website-color-highlight-alt)'>super easy</b>, we onboard Integral into a private region of our cloud, and they were able to install their solution and run all of the analysis and <b style='color:var(--website-color-highlight-alt)'>get us results in a few days</b>”",
    author: "CTO, Leading Health Tech Company",
  },
  {
    quote: "“It was <b style='color:var(--website-color-highlight-alt)'>fast, simple</b> and we have <b style='color:var(--website-color-highlight-alt)'>confidence</b> and trust in the results”",
    author: "Senior PM, Top 10 Life Sciences Company",
  },
]

const CustomerCallout = () => {
  return (
    <Box>
      <Box maxWidth="var(--website-width-max)" width={["100%", "90%"]} margin="auto" paddingTop="94px" paddingBottom="120px">
        <Text
          align="center"
          fontSize={["var(--website-font-size-h1-mobile)", "var(--website-font-size-h1)"]}
          fontWeight="400"
          width={["90%", "70%"]}
          lineHeight="1.2"
          margin="auto"
          marginBottom="32px"
          color="var(--website-color-h1-on-light)"
        >
          According to Our Customers, Integral is Different
        </Text>
        <Stack direction="row" spacing="34px" justifyContent={["flex-start", "space-evenly"]} px={["40px", "0px"]} alignItems="stretch" overflowX={["scroll", "hidden"]}>
          {CARD_CONTENT.map((contentBlock, i) => (
            <TestimonialCard key={i} text={contentBlock.quote} author={contentBlock.author} />
          ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default CustomerCallout
